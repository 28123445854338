import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import "moment-timezone";

// Components
import { FaAngleLeft, FaAngleRight, FaReply } from "react-icons/fa";
import { FiPlus, FiCornerDownLeft } from "react-icons/fi";

import { useTheme, Button, CircularProgress, Badge } from "@mui/material";

import CalendarPickerOverlay from "../../components/CalendarPickerOverlay";
import GoalsList from "../../components/GoalsList";
import DataInput from "../../components/DataInput";
import SubmitDayOverlay from "../../components/SubmitDayOverlay";
import CustomTooltip from "../../components/CustomTooltip";
import FAB from "../../components/FAB";
// import DeleteRecurringGoalOverlay from "../../components/DeleteRecurringGoalOverlay";
import MessageBubble from "../../components/MessageBubble";
import ImageModal from "../../components/ImageModal";
import RepeatPatternPickerOverlay from "../../components/RepeatPatternPickerOverlay";
import GoalsHeader from "../../components/GoalsHeader";
import InfoOverlay from "../../components/InfoOverlay";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { fetchGoals, unsubscribeGoals } from "../../redux/actions/goal";
import { fetchDay, unsubscribeDays } from "../../redux/actions/day";
import {
  fetchRecurrentGoals,
  unsubscribeRecurrentGoals,
} from "../../redux/actions/recurrentGoal";
import {
  lastTimeShowedGoalExampleAt,
  setShowCurrentMonthTargets,
  setLastTimeToggledGoalDone,
} from "../../redux/actions/local";

// Firestore
import {
  addGoal,
  undoDeleteGoal,
  toggleGoalDone,
  // sortGoals,
  instanceGoalFromRecurrentGoal,
  // deleteGoal,
} from "../../api/firestore/goals";
import { addDay, undoSubmitResults } from "../../api/firestore/days";
import { undoDeleteRecurrentGoal } from "../../api/firestore/recurrentGoals";
import { toggleTargetDone } from "../../api/firestore/targets";
import { addMessage, deleteMessage } from "../../api/firestore/chats";
import { isMonthCreated, addMonth } from "../../api/firestore/months";
import { updateUser } from "../../api/firestore/users";

// Analytics
import { analytics } from "../../api/firebase";

// Own api
// import { saveImage } from "../../api/filesHandler";
import { useWindowSize } from "../../api/helper";
import { playSound } from "../../api/filesHandler";
import first_goal_done from "../../assets/sounds/first_goal_done.wav";
import goal_done from "../../assets/sounds/goal_done.wav";
import target_done from "../../assets/sounds/target_done.wav";
import { en } from "../../api/languages/en";
import { Devices, goalsExamples } from "../../api/constants";
import "./style.scss";

const GoalsScreen = (props) => {
  const theme = useTheme();
  const goalsScreenRef = useRef();
  const effectRanCheckDate = useRef(false);
  const [, windowHeight] = useWindowSize();
  const location = useLocation();
  const dispatch = useDispatch();

  // Redux
  const currentDevice = useSelector((state) => state.local.currentDevice);
  const goals = useSelector((state) => state.goal.goals);
  const inboxes = useSelector((state) => state.inbox.inboxes);
  const recurrentGoals = useSelector(
    (state) => state.recurrentGoal.recurrentGoals
  );
  const yesterdayGoals = useSelector((state) => state.goal.yesterdayGoals);
  const yesterdayRecurrentGoals = useSelector(
    (state) => state.recurrentGoal.yesterdayRecurrentGoals
  );
  const day = useSelector((state) => state.day.day);
  const yesterday = useSelector((state) => state.day.yesterday);
  const user = useSelector((state) => state.user.user);
  const currentMonthTargets = useSelector(
    (state) => state.target.currentMonthTargets
  );
  const currentMonth = useSelector((state) => state.month.currentMonth);
  const showCurrentMonthTargets = useSelector(
    (state) => state.local.showCurrentMonthTargets
  );
  const nextMonthTargets = useSelector(
    (state) => state.target.nextMonthTargets
  );
  const lastTimeToggledGoalDone = useSelector(
    (state) => state.local.lastTimeToggledGoalDone
  );

  // States
  const [displayedGoals, setDisplayedGoals] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [progress, setProgress] = useState(0);
  const [goalToEdit, setGoalToEdit] = useState(true);
  // const [goalToDelete, setGoalToDelete] = useState(null);
  const [deletedGoal, setDeletedGoal] = useState(null);
  const [allowNavigation, setAllowNavigation] = useState(true);
  const [
    isPartiallyDoneGoalInfoOverlayVisible,
    setIsPartiallyDoneGoalInfoOverlayVisible,
  ] = useState(false);
  const [goalToHighlight, setGoalToHighlight] = useState(null);
  const [isGoalsInfoOverlayVisible, setIsGoalsInfoOverlayVisible] =
    useState(false);
  const [
    isUnsubmittedDayResultsInfoOverlayVisible,
    setIsUnsubmittedDayResultsInfoOverlayVisible,
  ] = useState(false);

  // Redux for randoms and examples
  const showedGoalExampleAt = useSelector(
    (state) => state.local.showedGoalExampleAt
  );
  const randomNumberMotivateMessageStart = useSelector(
    (state) => state.local.randomNumberMotivateMessageStart
  );
  const randomNumberMotivateMessage = useSelector(
    (state) => state.local.randomNumberMotivateMessage
  );
  const randomNumberGoalsExample = useSelector(
    (state) => state.local.randomNumberGoalsExample
  );

  // Loaders
  const isLoadingGoals = useSelector((state) => state.goal.isLoading);
  const isLoadingRecurrentGoals = useSelector(
    (state) => state.recurrentGoal.isLoading
  );
  const isLoadingDay = useSelector((state) => state.day.isLoadingDay);
  const isLoadingTargets = useSelector((state) => state.target.isLoading);
  const isLoadingYesterdayGoals = useSelector(
    (state) => state.goal.isLoadingYesterdayGoals
  );
  const isLoadingYesterdayRecurrentGoals = useSelector(
    (state) => state.recurrentGoal.isLoadingYesterdayRecurrentGoals
  );
  const [isLoadingUndo, setIsLoadingUndo] = useState(false);

  // Sounds
  const isSoundEffectsEnabled = useSelector(
    (state) => state.local.isSoundEffectsEnabled
  );
  const isGoalCompleteSoundEnabled = useSelector(
    (state) => state.local.isGoalCompleteSoundEnabled
  );

  // Overlays
  const [showCalendar, setShowCalendar] = useState(false);
  const [isSubmitDayOverlayVisible, setIsSubmitDayOverlayVisible] =
    useState(false);
  const [showImageFullScreenModal, setShowImageFullScreenModal] =
    useState(false);
  const [imageForFullScreenModal, setImageForFullScreenModal] = useState([{}]);
  const [showInput, setShowInput] = useState(false);
  // const [
  //   isDeleteRecurringGoalOverlayVisible,
  //   setIsDeleteRecurringGoalOverlayVisible,
  // ] = useState(false);
  const [
    isRepeatPatternPickerOverlayVisible,
    setIsRepeatPatternPickerOverlayVisible,
  ] = useState(false);

  // DataInput
  const [name, setName] = useState("");

  /////////////////// Sorting displayed Goals ///////////////////
  const isSortByIsDone = useSelector((state) => state.local.isSortByIsDone);
  const isSortBy = useSelector((state) => state.local.isSortBy);
  const isOrderBy = useSelector((state) => state.local.isOrderBy);

  /////////////////// Sorting Targets ///////////////////
  const isSortByTargetsIsDone = useSelector(
    (state) => state.local.isTargetsSortByIsDone
  );
  const isSortByTargets = useSelector((state) => state.local.isTargetsSortBy);
  const isOrderByTargets = useSelector((state) => state.local.isTargetsOrderBy);

  /////////////////// First goal of the day pressed hack ///////////////////
  // Had to use useRef as it is faster than redux or a state variable
  const ranFirstGoalOfTheDaySound = useRef(
    !lastTimeToggledGoalDone ||
      moment(lastTimeToggledGoalDone).isBefore(moment(), "day")
      ? false
      : true
  );

  /////////////////// Data Input ///////////////////
  const addGoalPressed = () => {
    setShowInput(true);
    setAllowNavigation(false);
  };

  /////////////////// Submit new goal ///////////////////
  const submitNewGoal = async (name) => {
    const goal = {
      name,
      position: displayedGoals.length.toString(),
      date: selectedDate,
      tag: "",
      description: "",
      isRolledOver: true,
    };

    let lastGoalAddedId = await addGoal(goal);

    if (!day) {
      addDay(selectedDate);
    }

    // Update last time showed goal example at
    dispatch(lastTimeShowedGoalExampleAt(moment()));

    // Reset
    setName("");

    setGoalToHighlight(lastGoalAddedId);

    analytics.logEvent("goal_created");
  };

  /////////////////// Highligh duplicated goal ///////////////////
  useEffect(() => {
    if (location.state && location.state.duplicatedGoalId) {
      setGoalToHighlight(location.state.duplicatedGoalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (goalToHighlight) {
      const highlightGoalTimeout = setTimeout(() => {
        setGoalToHighlight(null);
      }, 3000);

      return () => {
        clearTimeout(highlightGoalTimeout);
      };
    }
  }, [goalToHighlight]);

  /////////////////// Toggle Goal Done ///////////////////
  const goalBoxPressed = (itemPressed) => {
    // Check sound to play, toast message if:
    // - Is recurrent goal and as it's marked as done, gets instantiated
    // - Is marked as done
    if (
      !itemPressed.date ||
      (!itemPressed.isDone &&
        (itemPressed.isPartiallyDone === undefined ||
          !itemPressed.isPartiallyDone))
    ) {
      if (!ranFirstGoalOfTheDaySound.current) {
        if (isGoalCompleteSoundEnabled) {
          playSound(first_goal_done);
        }
        setTimeout(() => {
          toast.success("First goal of the day done!", 2);
        }, 1);
      } else {
        if (isGoalCompleteSoundEnabled) {
          playSound(goal_done);
        }
      }
      ranFirstGoalOfTheDaySound.current = true;
      dispatch(setLastTimeToggledGoalDone(moment()));
    }

    // Handle db update
    if (!itemPressed.date) {
      instanceGoalFromRecurrentGoal({
        ...itemPressed,
        date: selectedDate,
        isDone: true,
      });
    } else {
      if (itemPressed.isDone) {
        // Set to "partially done"
        toggleGoalDone({
          ...itemPressed,
          isDone: false,
          isPartiallyDone: true,
        });
        if (user && user.seenPartiallyDoneGoalInfo === undefined) {
          setIsPartiallyDoneGoalInfoOverlayVisible(true);
          updateUser({ seenPartiallyDoneGoalInfo: true });
        }
      } else if (itemPressed.isPartiallyDone) {
        // Set to "undone"
        toggleGoalDone({
          ...itemPressed,
          isDone: false,
          isPartiallyDone: false,
        });
      } else {
        // Set to "done"
        toggleGoalDone({
          ...itemPressed,
          isDone: true,
          isPartiallyDone: false,
        });
      }
    }

    if (!day) {
      addDay(selectedDate);
    }
  };

  /////////////////// Toggle Target Done ///////////////////
  const targetBoxPressed = async (itemPressed) => {
    if (
      isSoundEffectsEnabled &&
      !itemPressed.isDone &&
      (!itemPressed.isPartiallyDone ||
        itemPressed.isPartiallyDone === undefined)
    ) {
      playSound(target_done);
    }

    let messageDoneId;

    if (!itemPressed.isDone && !itemPressed.isPartiallyDone) {
      // Marked as "done"
      const message = `🎯 Target achieved\n${itemPressed.name}`;
      messageDoneId = await addMessage({
        text: message,
        isTargetAchieved: moment(),
      });
    } else {
      // Marked as "undone"
      if (itemPressed.messageDoneId) {
        deleteMessage({ id: itemPressed.messageDoneId }, false);
      }
    }

    if (itemPressed.isDone) {
      // Set to "partially done"
      toggleTargetDone({
        ...itemPressed,
        isDone: false,
        isPartiallyDone: true,
        messageDoneId: messageDoneId,
      });
    } else if (itemPressed.isPartiallyDone) {
      // Set to "undone"
      toggleTargetDone({
        ...itemPressed,
        isDone: false,
        isPartiallyDone: false,
        messageDoneId: messageDoneId,
      });
    } else {
      // Set to "done"
      toggleTargetDone({
        ...itemPressed,
        isDone: true,
        isPartiallyDone: false,
        messageDoneId: messageDoneId,
      });
    }

    const isTheMonthCreated = await isMonthCreated(selectedDate);
    if (!isTheMonthCreated) {
      addMonth(
        moment(
          itemPressed.date &&
            itemPressed.date.toDate &&
            itemPressed.date.toDate()
        )
      );
    }
  };

  // Used for delete shortcut
  /////////////////// Delete Goal ///////////////////
  // const deleteGoalPressed = (item, deleteAllEntries = false) => {
  //   if (!item.date || item.fromRecurrentGoal) {
  //     setIsDeleteRecurringGoalOverlayVisible(true);
  //   } else {
  //     deleteGoal(item);

  //     // For undo delete goal
  //     setDeletedGoal(item);
  //   }
  // };

  /////////////////// Title ///////////////////
  const getSelectedDateTitle = () => {
    let title = "";
    moment(selectedDate).format("YMD") === moment().format("YMD")
      ? (title = "Today")
      : moment(selectedDate).add(1, "days").format("YMD") ===
        moment().format("YMD")
      ? (title = "Yesterday")
      : moment(selectedDate).subtract(1, "days").format("YMD") ===
        moment().format("YMD")
      ? (title = "Tomorrow")
      : (title = moment(selectedDate).format("dddd"));
    return title;
  };

  /////////////////// Calendar picker ///////////////////
  const selectDate = useCallback((date = moment()) => {
    setDisplayedGoals([]);

    // Start a new listener every time the date changes
    unsubscribeGoals();
    unsubscribeRecurrentGoals();
    unsubscribeDays();
    dispatch(fetchGoals(moment(date)));
    dispatch(fetchRecurrentGoals(moment(date)));
    dispatch(fetchDay(moment(date)));

    setSelectedDate(date);
    setShowCalendar(false);
  }, []);

  useEffect(() => {
    dispatch(fetchGoals(moment()));
    dispatch(fetchRecurrentGoals(moment()));
    dispatch(fetchDay(moment()));
  }, []);

  // Make goals screen "start day" since 3am instead of 12am
  // Only run ONCE
  useEffect(() => {
    if (!effectRanCheckDate.current) {
      if (moment() < moment().hour(3)) {
        selectDate(moment().subtract(1, "days"));
      }
    }

    return () => {
      effectRanCheckDate.current = true;
    };
  }, []);

  useEffect(() => {
    if (user?.seenGoalsInfo === undefined && user?.guide?.includes("chat")) {
      setIsGoalsInfoOverlayVisible(true);
      updateUser({ seenGoalsInfo: true });
    }
  }, [props.isCoachInfoOverlayVisible]);

  useEffect(() => {
    if (
      !isLoadingYesterdayGoals &&
      !isLoadingYesterdayRecurrentGoals &&
      moment(selectedDate).format("YYYYMMDD") === moment().format("YYYYMMDD") &&
      user?.seenGoalsInfo &&
      user?.seenUnsubmittedDayResultsInfo === undefined &&
      (yesterday?.isSubmitted === false || !yesterday) &&
      (yesterdayGoals?.length || yesterdayRecurrentGoals?.length)
    ) {
      setIsUnsubmittedDayResultsInfoOverlayVisible(true);
      updateUser({ seenUnsubmittedDayResultsInfo: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    yesterdayGoals,
    yesterdayRecurrentGoals,
    isLoadingYesterdayGoals,
    isLoadingYesterdayRecurrentGoals,
  ]);

  useEffect(() => {
    if (!isLoadingGoals && !isLoadingRecurrentGoals) {
      const newGoalsList = goals.concat(
        // Discards recurrent goals that are instanced
        recurrentGoals
          .filter((el) => goals.every((it) => it.fromRecurrentGoal !== el.id))
          .filter(
            (el) =>
              !el.skippedDates ||
              (el.skippedDates &&
                !el.skippedDates.includes(
                  moment(selectedDate).format("YYYYMMDD")
                ))
          )
      );

      setDisplayedGoals(newGoalsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goals, recurrentGoals, isLoadingGoals, isLoadingRecurrentGoals]);

  // To go back to corresponding day after editing/deleting goal or going back
  useEffect(() => {
    if (location.state && location.state.date) {
      selectDate(moment(location.state.date));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state && location.state.date]);

  /////////////////// Progress ///////////////////
  useEffect(() => {
    if (displayedGoals.length > 0) {
      setProgress(
        (displayedGoals.filter((a) => a.isDone).length /
          displayedGoals.length) *
          100
      );
    } else {
      setProgress(0);
    }
  }, [displayedGoals, selectedDate]);

  /////////////////// Undo Delete Goal ///////////////////
  useEffect(() => {
    if (location.state && location.state.goal) {
      setDeletedGoal({
        ...location.state.goal,
        ...(location.state.goal.date && {
          date: moment(location.state.date),
        }),
        ...(location.state.goal.startDate && {
          startDate: moment(location.state.goal.startDate),
        }),
        ...(location.state.goal.endDate && {
          endDate: moment(location.state.goal.endDate),
        }),
      });
    }
  }, [location.state]);

  // Forget last deleted goal after 5 seconds
  useEffect(() => {
    if (deletedGoal) {
      const deletedGoalTimeout = setTimeout(() => {
        setDeletedGoal(null);
      }, 5000);
      return () => {
        clearTimeout(deletedGoalTimeout);
      };
    }
  }, [deletedGoal]);

  const restoreDeletedGoal = () => {
    if (!deletedGoal.date || deletedGoal.fromRecurrentGoal) {
      undoDeleteRecurrentGoal({
        ...deletedGoal,
        // If it has skippedDates, try to remove the day it might have been deleted from (this is for option "Only for today")
        ...(deletedGoal.skippedDates?.length && {
          skippedDates: deletedGoal.skippedDates.filter(
            (el) => el !== moment(location.state.goal.date).format("YYYYMMDD")
          ),
        }),
      });
    } else {
      undoDeleteGoal(deletedGoal);
    }
    setDeletedGoal(null);
  };

  /////////////////// Undo Submit Day ///////////////////
  const undoSubmit = async () => {
    setIsLoadingUndo(true);
    await undoSubmitResults(selectedDate, day);
    setIsLoadingUndo(false);
    setAllowNavigation(true);
  };

  useEffect(() => {
    if (day && !day.isSubmitted) {
      setIsLoadingUndo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day && !day.isSubmitted]);

  /////////////////// Shortcuts ///////////////////
  useEffect(() => {
    if (allowNavigation) {
      goalsScreenRef?.current?.focus();
    }
  }, [allowNavigation]);

  useEffect(() => {
    let listenerRefValue = null;

    if (goalsScreenRef.current) {
      goalsScreenRef.current.addEventListener("keydown", downHandler);
      listenerRefValue = goalsScreenRef.current;
    }

    return () => {
      if (listenerRefValue) {
        listenerRefValue.removeEventListener("keydown", downHandler);
      }
    };
  });

  const downHandler = (key) => {
    if (allowNavigation) {
      switch (key.keyCode) {
        case 37:
          selectDate(moment(selectedDate).subtract(1, "days"));
          break;
        case 39:
          selectDate(moment(selectedDate).add(1, "days"));
          break;
        case 13:
          setShowInput(true);
          setAllowNavigation(false);
          break;
        default:
          break;
      }
    } else {
      if (key.keyCode === 27) {
        handleLastTimeShowedExampleAt();
        setShowInput(false);
        setAllowNavigation(true);
      }
    }
  };

  const handleLastTimeShowedExampleAt = () => {
    dispatch(lastTimeShowedGoalExampleAt(moment()));
  };

  // Styles for screen height
  const styles = {
    // notForPhones: {
    //   height:
    //     currentDevice === "PHONE"
    //       ? "calc(100vh - 158.5px)"
    //       : "calc(100vh - 90px)",
    // },
    notForPhones: {
      height: currentDevice !== "PHONE" && "calc(100vh - 90px)",
      overflowY: currentDevice !== "PHONE" && "scroll",
    },
  };

  /////////////////// Render ///////////////////
  return (
    <div
      tabIndex="0"
      ref={goalsScreenRef}
      id="goals-screen"
      className="goals-screen-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
        height: currentDevice === "PHONE" ? windowHeight - 70 : null,
      }}
    >
      <div
        className="goals-screen-content-container"
        style={{ backgroundColor: theme.palette.background.main }}
      >
        {/* Title for the day */}
        <div className="title-navigation-container">
          {moment(
            user?.createdTime &&
              user.createdTime.toDate &&
              user.createdTime.toDate()
          )
            .startOf("day")
            .isBefore(moment(selectedDate).startOf("day")) ? (
            <div className="left-arrow-container">
              <button
                onClick={() => {
                  selectDate(moment(selectedDate).subtract(1, "days"));
                }}
                className="btn-nav-days"
              >
                <Badge
                  variant="standard"
                  color="error"
                  invisible={
                    moment(selectedDate).format("YYYYMMDD") ===
                      moment().format("YYYYMMDD") &&
                    (!yesterday?.isSubmitted || !yesterday) &&
                    (yesterdayGoals?.length || yesterdayRecurrentGoals?.length)
                      ? false
                      : true
                  }
                  badgeContent="1"
                >
                  <FaAngleLeft
                    size={28}
                    style={{ color: theme.palette.gray.contrast }}
                  />
                </Badge>
                <label
                  className="btn-nav-days-text"
                  style={{ color: theme.palette.gray.contrast }}
                >
                  {getSelectedDateTitle() === "Tomorrow"
                    ? "Today"
                    : getSelectedDateTitle() === "Today"
                    ? "Yesterday"
                    : "Previous"}
                </label>
              </button>
            </div>
          ) : null}

          <button
            onClick={() => setShowCalendar(!showCalendar)}
            className="btn-nav-calendar"
            style={{ backgroundColor: theme.palette.background.main }}
          >
            <div>
              <label
                className="title-day"
                style={{ color: theme.palette.text.main }}
              >
                {getSelectedDateTitle()}
              </label>
            </div>
            <div>
              <label
                className="title-date"
                style={{ color: theme.palette.gray.contrast }}
              >
                {moment(selectedDate).format(
                  getSelectedDateTitle() === "Today" ||
                    getSelectedDateTitle() === "Yesterday" ||
                    getSelectedDateTitle() === "Tomorrow"
                    ? "ddd MMM D"
                    : "MMM D"
                )}
              </label>
            </div>
          </button>

          <div className="right-arrow-container">
            <button
              onClick={() => {
                selectDate(moment(selectedDate).add(1, "days"));
              }}
              className="btn-nav-days"
            >
              <label
                className="btn-nav-days-text"
                style={{ color: theme.palette.gray.contrast }}
              >
                {getSelectedDateTitle() === "Yesterday"
                  ? "Today"
                  : getSelectedDateTitle() === "Today"
                  ? "Tomorrow"
                  : "Next"}
              </label>
              <FaAngleRight
                size={28}
                style={{ color: theme.palette.gray.contrast }}
              />
            </button>
          </div>
        </div>

        <div
          className="goals-screen-scroll-section-container"
          style={styles.notForPhones}
        >
          {/* Back to today button */}
          {moment(selectedDate).format("YYYYMMDD") !==
          moment().format("YYYYMMDD") ? (
            <div className="back-to-today-button-container">
              <Button
                variant="outlined"
                color="gray"
                onClick={() => {
                  selectDate();
                  goalsScreenRef?.current?.focus();
                }}
                sx={{ height: 60, width: 180 }}
                fullWidth
              >
                <FiCornerDownLeft size={20} color={theme.palette.gray.main} />
                <label className="back-to-today-button-text">Today</label>
              </Button>
            </div>
          ) : null}

          {/* Motivate Message */}
          {moment(selectedDate).format("L") === moment().format("L") ? (
            <GoalsHeader
              day={day}
              randomNumberMotivateMessageStart={
                randomNumberMotivateMessageStart
              }
              randomNumberMotivateMessage={randomNumberMotivateMessage}
              user={user}
              progress={progress}
            />
          ) : null}

          {/* Goals List */}
          {isLoadingGoals ||
          isLoadingRecurrentGoals ||
          isLoadingDay ||
          isLoadingTargets ? (
            <div className="loading-day-spinner-container">
              <CircularProgress
                color="primary"
                className="loading-day-spinner"
              />
            </div>
          ) : (
            <div
              style={{
                paddingBottom: currentDevice === Devices.PHONE ? 170 : 120,
              }}
            >
              {displayedGoals.length > 0 ? (
                <>
                  <GoalsList
                    goals={displayedGoals}
                    goalBoxPressedHandler={goalBoxPressed}
                    // setGoalToDelete={setGoalToDelete} // Used for delete shortcut
                    // deleteGoalPressedHandler={deleteGoalPressed} // Used for delete shortcut
                    title={getSelectedDateTitle()}
                    // sortHandler={sortGoals}
                    isSubmitted={
                      (day && day.isSubmitted) ||
                      (!day &&
                        selectedDate <
                          moment().subtract(1, "days").startOf("day"))
                    }
                    sortByIsDone={isSortByIsDone}
                    sortBy={isSortBy}
                    orderBy={isOrderBy}
                    date={selectedDate}
                    setAllowNavigation={setAllowNavigation}
                    isDataInputVisible={showInput}
                    setGoalToEdit={setGoalToEdit}
                    setIsRepeatPatternPickerOverlayVisible={
                      setIsRepeatPatternPickerOverlayVisible
                    }
                    itemToHighlight={goalToHighlight}
                    isGoal // Use this instead of editing in this file in order to prevent doing weird things passing dates
                  />

                  {/* Client and coach comments and images */}
                  {day &&
                    day.isSubmitted &&
                    (!!day.clientComment ||
                      !!day.coachComment ||
                      (!!day.images && day.images.length > 0)) && (
                      <div className="day-submitted-content-container">
                        {!!day.clientComment && (
                          <div className="messageSentContainer">
                            <MessageBubble
                              mine
                              text={day.clientComment}
                              subjectiveWellBeingScale={
                                day.subjectiveWellBeingScale !== undefined
                                  ? day.subjectiveWellBeingScale
                                  : null
                              }
                            />
                          </div>
                        )}
                        {!!day.coachComment && (
                          <div className="messageReceivedContainer">
                            <MessageBubble
                              text={day.coachComment}
                              isConversation
                            />
                          </div>
                        )}
                        {!!day.images && day.images.length > 0 && (
                          <div className="images-preview-container">
                            {day.images.map((image, index) => (
                              <div key={index} className="img-wrap">
                                <img
                                  src={image}
                                  className="image-preview"
                                  onClick={() => {
                                    setShowImageFullScreenModal(true);
                                    setImageForFullScreenModal(image);
                                  }}
                                  height="100"
                                  width="100"
                                  alt="day img preview"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}

                  {!showInput &&
                    ((day && !day.isSubmitted) ||
                      (!day &&
                        moment(selectedDate).startOf("day").format() >=
                          moment()
                            .subtract(1, "days")
                            .startOf("day")
                            .format())) && (
                      <div
                        style={{
                          marginLeft: 35,
                          marginTop: 10,
                        }}
                      >
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={addGoalPressed}
                          sx={{
                            height: 30,
                            // width: 150,
                          }}
                        >
                          Add goal
                        </Button>
                      </div>
                    )}
                </>
              ) : (
                !showInput && (
                  <div className="no-goals-container">
                    <div className="no-goals-content">
                      <label
                        className="no-goals-title"
                        style={{ color: theme.palette.text.main }}
                      >
                        Nothing yet
                      </label>
                      <label
                        className="no-goals-text"
                        style={{ color: theme.palette.gray.light }}
                      >
                        {moment(selectedDate).format("D") ===
                        moment().format("D")
                          ? "Add your first goal \n eg " +
                            goalsExamples[randomNumberGoalsExample]
                          : "No goals for the day"}
                      </label>
                    </div>
                  </div>
                )
              )}

              {/* This month's targets. Or next month's targets if current month is already submitted or has no targets, and selected date is in the last 7 days of the month */}
              {!showInput &&
              !currentMonth?.isSubmitted &&
              currentMonthTargets?.length > 0 &&
              moment(selectedDate).format("YYYYMM") ===
                moment().format("YYYYMM") ? (
                <div style={{ paddingTop: 20 }}>
                  <GoalsList
                    goals={currentMonthTargets}
                    goalBoxPressedHandler={targetBoxPressed}
                    title={
                      moment(selectedDate).format("MMMM").toUpperCase() +
                      `'S TARGETS`
                    }
                    counter={
                      currentMonthTargets &&
                      currentMonthTargets.filter((target) => !target.isDone)
                        .length
                    }
                    sortByIsDone={isSortByTargetsIsDone}
                    sortBy={
                      isSortByTargets && isSortByTargets !== "position"
                        ? isSortByTargets
                        : "name"
                    }
                    orderBy={isOrderByTargets}
                    isCollapsible
                    setShowImageFullScreenModal={setShowImageFullScreenModal}
                    setImageForFullScreenModal={setImageForFullScreenModal}
                    setAllowNavigation={setAllowNavigation}
                    showItems={showCurrentMonthTargets}
                    setShowItems={() => {
                      updateUser({
                        showCurrentMonthTargets: !showCurrentMonthTargets,
                      });
                      dispatch(
                        setShowCurrentMonthTargets(!showCurrentMonthTargets)
                      );
                    }}
                    navigateToTargetsScreen
                    isTarget
                  />
                </div>
              ) : !showInput &&
                nextMonthTargets?.length > 0 &&
                moment(selectedDate).format("YYYYMM") ===
                  moment().format("YYYYMM") &&
                moment(selectedDate) >
                  moment().endOf("month").subtract(6, "days") ? (
                <div style={{ paddingTop: 20 }}>
                  <GoalsList
                    goals={nextMonthTargets}
                    goalBoxPressedHandler={targetBoxPressed}
                    title={
                      moment(selectedDate)
                        .add(1, "months")
                        .format("MMMM")
                        .toUpperCase() + `'S TARGETS`
                    }
                    counter={
                      nextMonthTargets &&
                      nextMonthTargets.filter((target) => !target.isDone).length
                    }
                    sortByIsDone={isSortByTargetsIsDone}
                    sortBy={
                      isSortByTargets && isSortByTargets !== "position"
                        ? isSortByTargets
                        : "name"
                    }
                    orderBy={isOrderByTargets}
                    isCollapsible
                    setShowImageFullScreenModal={setShowImageFullScreenModal}
                    setImageForFullScreenModal={setImageForFullScreenModal}
                    setAllowNavigation={setAllowNavigation}
                    showItems={showCurrentMonthTargets}
                    setShowItems={() => {
                      updateUser({
                        showCurrentMonthTargets: !showCurrentMonthTargets,
                      });
                      dispatch(
                        setShowCurrentMonthTargets(!showCurrentMonthTargets)
                      );
                    }}
                    navigateToTargetsScreen
                    isTarget
                  />
                </div>
              ) : null}
            </div>
          )}

          {(!isLoadingGoals || !isLoadingRecurrentGoals || !isLoadingDay) &&
            !isSubmitDayOverlayVisible &&
            ((day && !day.isSubmitted) ||
              (!day &&
                moment(selectedDate).startOf("day").format() >=
                  moment().subtract(1, "days").startOf("day").format())) &&
            showInput && (
              <DataInput
                onSubmitHandler={submitNewGoal}
                placeholder={
                  !showedGoalExampleAt ||
                  moment(showedGoalExampleAt).format("YYYYMMDD") <
                    moment().format("YYYYMMDD")
                    ? "eg " + goalsExamples[randomNumberGoalsExample]
                    : "What's your next goal?"
                }
                value={name}
                setValue={setName}
                setIsVisible={setShowInput}
                setAllowNavigation={setAllowNavigation}
                handleLastTimeShowedExampleAt={handleLastTimeShowedExampleAt}
                btnAddTitle="Add goal"
                autoFocus
              />
            )}
        </div>
      </div>

      <div
        className="float-btns-container"
        style={{
          position: currentDevice === Devices.PHONE ? "fixed" : "absolute",
          bottom: currentDevice === Devices.PHONE ? 50 : 0,
        }}
      >
        {/* Add goal button */}
        {!isSubmitDayOverlayVisible && ((day && !day.isSubmitted) || !day) && (
          <FAB
            position="right"
            icon={<FiPlus size={38} />}
            // title={currentDevice !== Devices.PHONE ? "Create" : ""}
            isVisible={!showInput}
            handleClick={addGoalPressed}
          />
        )}

        {/* Undo button */}
        {/* {deletedGoal && ( */}
        <FAB
          position="left"
          icon={<FaReply size={30} />}
          isVisible={deletedGoal}
          handleClick={restoreDeletedGoal}
        />
        {/* )} */}

        {/* Submit Day button and overlay */}
        {/* Show if
          - Selected date is present or past
          AND
          - Day has goals
            AND
              - Day exists and is not submitted
              OR
              - Day doesn't exist
          OR
          - Submit day overlay is visible
        */}
        {((moment(selectedDate).startOf("day") <= moment().startOf("day") &&
          displayedGoals?.length &&
          ((day && !day.isSubmitted) || !day)) ||
          isSubmitDayOverlayVisible) && (
          <>
            {/* Only show button if overlay and data input are not visible */}
            {!isSubmitDayOverlayVisible && !showInput && (
              <Button
                variant="contained"
                color={
                  moment(selectedDate) < moment().startOf("day") ||
                  (moment().format("H") >= 18 && progress)
                    ? "primary"
                    : "quaternary"
                }
                sx={{
                  width: 180,
                  height: 60,
                  position: "absolute",
                  marginLeft: "auto",
                  marginRight: "auto",
                  left: 0,
                  right: 0,
                  bottom: 40,
                  color:
                    moment(selectedDate) < moment().startOf("day") ||
                    (moment().format("H") >= 18 && progress)
                      ? theme.palette.light.main
                      : theme.palette.gray.contrast,
                }}
                onClick={() => {
                  setAllowNavigation(false);
                  setIsSubmitDayOverlayVisible(true);
                }}
              >
                Submit day
                {isLoadingDay
                  ? null
                  : moment(selectedDate).format("YMD") ===
                      moment().subtract(1, "days").format("YMD") && (
                      <CustomTooltip text="⏰ 😅" />
                    )}
              </Button>
            )}

            <SubmitDayOverlay
              isVisible={isSubmitDayOverlayVisible}
              setIsVisible={setIsSubmitDayOverlayVisible}
              date={selectedDate}
              clientComment={(day && day.clientComment) || ""}
              dayImages={
                day && !!day.images && day.images.length > 0 && day.images
              }
              subjectiveWellBeingScale={
                day && day.subjectiveWellBeingScale !== undefined
                  ? day.subjectiveWellBeingScale
                  : null
              }
              goals={displayedGoals}
              goalBoxPressedHandler={goalBoxPressed}
              // isSubmitForced={yesterday && yesterday.isSubmitted === false}
              customSubmitMessage={user && user.customSubmitMessage}
              progress={progress}
              setAllowNavigation={setAllowNavigation}
            />
          </>
        )}

        {/* Undo Submit Day */}
        {(day?.isSubmitted && !isSubmitDayOverlayVisible) ||
        (!day &&
          displayedGoals.length &&
          moment(selectedDate).startOf("day") <
            moment().subtract(1, "days").startOf("day")) ? (
          <Button
            variant="outlined"
            color="gray"
            onClick={() => {
              setAllowNavigation(false);
              undoSubmit();
            }}
            fullWidth
            sx={{
              width: 180,
              height: 60,
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
              bottom: 40,
              backgroundColor: theme.palette.background.main,
            }}
          >
            {isLoadingUndo ? (
              <CircularProgress color="primary" />
            ) : (
              <>
                <FaReply /> &nbsp; Undo submit
              </>
            )}
          </Button>
        ) : null}
      </div>

      {/* Calendar Picker Modal */}
      <CalendarPickerOverlay
        isVisible={showCalendar}
        setIsVisible={setShowCalendar}
        selectedDate={selectedDate}
        onDateChangeHandler={selectDate}
        minDate={moment(
          user?.createdTime &&
            user.createdTime.toDate &&
            user.createdTime.toDate()
        )}
        undoneInboxes={
          inboxes?.length && inboxes?.filter((el) => !el.isDone)?.length
        }
      />

      {/* Modal for fullscreen images */}
      <ImageModal
        isVisible={showImageFullScreenModal}
        setIsVisible={setShowImageFullScreenModal}
        image={imageForFullScreenModal}
      />

      {/* <DeleteRecurringGoalOverlay
        isVisible={isDeleteRecurringGoalOverlayVisible}
        setIsVisible={setIsDeleteRecurringGoalOverlayVisible}
        goal={goalToDelete}
        date={selectedDate && moment(selectedDate)}
        setDeletedGoal={setDeletedGoal}
      /> */}

      <RepeatPatternPickerOverlay
        isVisible={isRepeatPatternPickerOverlayVisible}
        setIsVisible={setIsRepeatPatternPickerOverlayVisible}
        goal={goalToEdit}
        setGoal={setGoalToEdit}
        date={moment(selectedDate || undefined)}
      />

      <InfoOverlay
        isVisible={isPartiallyDoneGoalInfoOverlayVisible}
        setIsVisible={setIsPartiallyDoneGoalInfoOverlayVisible}
        title={en.INFO_PARTIALLY_DONE_GOAL_TITLE}
        subtitle={en.INFO_PARTIALLY_DONE_GOAL}
        textOk={"Got it"}
      />

      <InfoOverlay
        isVisible={isGoalsInfoOverlayVisible}
        setIsVisible={setIsGoalsInfoOverlayVisible}
        title={en.INFO_TITLE_GOALS}
        subtitle={en.INFO_GOALS}
        textOk={"Got it"}
      />

      <InfoOverlay
        isVisible={isUnsubmittedDayResultsInfoOverlayVisible}
        setIsVisible={setIsUnsubmittedDayResultsInfoOverlayVisible}
        title={en.INFO_TITLE_UNSUBMITTED_DAY_RESULTS}
        subtitle={en.INFO_UNSUBMITTED_DAY_RESULTS}
        textOk={"Got it"}
      />
    </div>
  );
};

export default React.memo(GoalsScreen);
