import React, { useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Splide
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "../../api/splide-goalswon.css";

import { useDispatch, useSelector } from "react-redux";
import { fetchCoach } from "../../redux/actions/coach";

import { useTheme } from "@mui/material";

//Screens
import Screen0 from "./screen0";
import Screen1 from "./screen1";
import Screen2 from "./screen2";
import Screen3 from "./screen3";
import Screen4 from "./screen4";
import Screen5 from "./screen5";
import Screen6 from "./screen6";
import Screen7 from "./screen7";
// import Screen8 from "./screen8";

// Analytics
import { analytics } from "../../api/firebase";

import welcome_tutorial from "../../assets/sounds/welcome_tutorial.wav";
import { playSound } from "../../api/filesHandler";
import "./style.scss";

const TutorialNavigation = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const tutorialRef = useRef();

  // Redux
  const user = useSelector((state) => state.user.user);
  const coach = useSelector((state) => state.coach.coach);
  const quiz = useSelector((state) => state.quiz.quiz);
  const tags = useSelector((state) => state.tags.tags);

  useEffect(() => {
    if (user?.id && user?.coach) {
      dispatch(fetchCoach(user?.coach));
    }
  }, [user?.id, user?.coach]);

  useEffect(() => {
    playSound(welcome_tutorial);
    analytics.logEvent("tutorial_begin");
  }, []);

  return (
    <>
      <div style={{ backgroundColor: theme.palette.background.main }}>
        <Splide
          options={{
            arrows: false, // Hide arrows
            pagination: false, // Hide dots
            drag: false,
            keyboard: false,
          }}
          ref={tutorialRef}
        >
          <SplideSlide>
            <Screen0 tutorialRef={tutorialRef} />
          </SplideSlide>
          <SplideSlide>
            <Screen1 tutorialRef={tutorialRef} coach={coach} />
          </SplideSlide>
          <SplideSlide>
            <Screen2 tutorialRef={tutorialRef} />
          </SplideSlide>
          <SplideSlide>
            <Screen3 tutorialRef={tutorialRef} quiz={quiz} />
          </SplideSlide>
          <SplideSlide>
            <Screen4 tutorialRef={tutorialRef} coach={coach} />
          </SplideSlide>
          <SplideSlide>
            <Screen5 tutorialRef={tutorialRef} />
          </SplideSlide>
          <SplideSlide>
            <Screen6 tutorialRef={tutorialRef} quiz={quiz} tags={tags} />
          </SplideSlide>
          <SplideSlide>
            <Screen7 tutorialRef={tutorialRef} user={user} coach={coach} />
          </SplideSlide>
          {/* <SplideSlide>
            <Screen8 user={user} coach={coach} />
          </SplideSlide> */}
        </Splide>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        // pauseOnFocusLoss
        // pauseOnHover
        theme="light"
      />
    </>
  );
};

export default React.memo(TutorialNavigation);

//---------------------

// import React, { useRef, useState } from "react";
// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";

// import "./style.scss";

// // import required modules
// import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

// const TutorialNavigation = () => {
//   return (
//     <>
//       <Swiper
//         cssMode={true}
//         navigation={true}
//         pagination={true}
//         mousewheel={true}
//         keyboard={true}
//         modules={[Navigation, Pagination, Mousewheel, Keyboard]}
//         className="mySwiper"
//       >
//         <SwiperSlide>Slide 1</SwiperSlide>
//         <SwiperSlide>Slide 2</SwiperSlide>
//         <SwiperSlide>Slide 3</SwiperSlide>
//         <SwiperSlide>Slide 4</SwiperSlide>
//         <SwiperSlide>Slide 5</SwiperSlide>
//         <SwiperSlide>Slide 6</SwiperSlide>
//         <SwiperSlide>Slide 7</SwiperSlide>
//         <SwiperSlide>Slide 8</SwiperSlide>
//         <SwiperSlide>Slide 9</SwiperSlide>
//       </Swiper>
//     </>
//   );
// };

// export default TutorialNavigation;

//----------------------------------------

// import React from "react";

// // ---- Swiper ----
// // Core modules imports are same as usual
// import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
// // Direct React component imports
// import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
// // Styles must use direct files imports
// import "swiper/swiper.scss"; // core Swiper
// import "swiper/modules/navigation/navigation.scss"; // Navigation module
// import "swiper/modules/pagination/pagination.scss"; // Pagination module

// import { useTheme } from "@mui/material";

// //Screens
// import Screen1 from "./screen1";
// // import Screen2 from "./screen2";
// // import Screen3 from "./screen3";
// // import Screen4 from "./screen4";
// // import Screen5 from "./screen5";
// // import Screen6 from "./screen6";
// // import Screen7 from "./screen7";

// import { colors } from "../../api/constants";
// import "./style.scss";

// const TutorialNavigation = () => {
//   const theme = useTheme();

//   return (
//     <Swiper
//       cssMode={true}
//       navigation={true}
//       pagination={true}
//       mousewheel={true}
//       keyboard={true}
//       modules={[Navigation, Pagination, Mousewheel, Keyboard]}
//       className="mySwiper"
//     >
//       <SwiperSlide>xd</SwiperSlide>

//       {/* <Screen1 /> */}
//       {/* <Screen2 /> */}
//       {/* <Screen3 /> */}
//       {/* <Screen4 /> */}
//       {/* <Screen5 /> */}
//       {/* <Screen6 /> */}
//       {/* <Screen7 /> */}
//     </Swiper>
//   );
// };

// export default TutorialNavigation;
