import { auth, storage } from "../api/firebase";
import moment from "moment";
import Resizer from "react-image-file-resizer";
import { Howl } from "howler";

const compressImage = async (imageFile) => {
  return await new Promise((resolve) => {
    Resizer.imageFileResizer(
      imageFile,
      1000,
      1000,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
};

export const uploadImage = async (image) => {
  const compressedImage = await compressImage(image);

  const url = compressedImage.name;

  // Filename creation
  let filename = url.substring(url.lastIndexOf("/") + 1); // Extract whole filename (eg: IMG_0006.JPG)
  // Add timestamp to fileName so it does not override another file with the same name
  const extension = filename.split(".").pop(); // Extract file's extension
  const name = filename.split(".").slice(0, -1).join("."); // Extract file's name
  filename = `${name}_${Date.now()}.${extension}`; // Set new filename with timestamp

  // File will be located at useruid/day/filename.fileExtension
  const refPath = `${auth.currentUser.uid}/${moment().format(
    "YYYYMMDD"
  )}/${filename}`;

  await storage.ref(refPath).put(compressedImage);

  return refPath;
};

export const getImagePath = async (image) => {
  const url = await storage.ref(image).getDownloadURL();

  return url;
};

export const playSound = (soundFile) => {
  var sound = new Howl({
    src: [soundFile],
  });
  sound.play();
};
