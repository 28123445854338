import { firestore, auth } from "../../api/firebase";
import moment from "moment";
import "moment-timezone";
import { captureException } from "@sentry/react";

import {
  FETCH_RECURRENT_GOALS,
  SET_LOADING_RECURRENT_GOALS,
  FETCH_YESTERDAY_RECURRENT_GOALS,
  SET_LOADING_YESTERDAY_RECURRENT_GOALS,
} from "../types";

import { recurrencePatternEnum } from "../../api/constants";

let unsubscribers = [];
let unsubscribersRecurrentGoals = [];

const fetchRecurrentGoalsSuccess = (recurrentGoals) => ({
  type: FETCH_RECURRENT_GOALS,
  payload: recurrentGoals,
});

const fetchYesterdayRecurrentGoalsSuccess = (recurrentGoals) => ({
  type: FETCH_YESTERDAY_RECURRENT_GOALS,
  payload: recurrentGoals,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_RECURRENT_GOALS,
  payload: isLoading,
});

const setLoadingYesterdayRecurrentGoals = (isLoading) => ({
  type: SET_LOADING_YESTERDAY_RECURRENT_GOALS,
  payload: isLoading,
});

// TODO consider if pagination is necessary, because Firebase suggests to avoid frequently churning listeners
// https://firebase.google.com/docs/firestore/best-practices#realtime_updates
// But this guy says that it won't cause technical issues (just not the cleanest approach)
// https://stackoverflow.com/a/66979317/8111691
export const fetchRecurrentGoals =
  (date = moment()) =>
  (dispatch) => {
    try {
      const userUid = auth.currentUser.uid;

      dispatch(setLoading(true));
      dispatch(fetchRecurrentGoalsSuccess([]));

      // Create a query for retrieving goals based on the given date
      const recurrentGoalsQuery = firestore
        .collection("recurrentGoals")
        .doc(userUid)
        .collection("recurrentGoals")
        .where(
          "startDate",
          "<=",
          moment(date).startOf("day").utcOffset(0, true).toDate()
        );
      // TODO - endDate might be null too -> how to conditionally where
      // .where(
      //   "endDate",
      //   ">=",
      //   moment().endOf("day").utcOffset(0, true).toDate()
      // )

      // Create a snapshot listener for the query
      const recurrentGoalsSubscriber = recurrentGoalsQuery.onSnapshot(
        (snapshot) => {
          // Map the documents to goals array with necessary transformations
          const recurrentGoals = snapshot.docs.reduce(function (result, doc) {
            const data = doc.data();

            const getDate = (field) =>
              moment(data[field]?.toDate && data[field].toDate())
                .utc()
                .utcOffset(moment().utcOffset(), true);

            const goal = {
              ...data,
              id: doc.id,
              ...(data?.date && { date: getDate("date") }),
              ...(data?.startDate && { startDate: getDate("startDate") }),
              ...(data?.endDate && { endDate: getDate("endDate") }),
            };

            if (!goal.endDate || moment(goal.endDate) > moment(date)) {
              // If recurrent goal hasn't ended in that day already
              if (goal.recurrencePattern === recurrencePatternEnum.DAILY) {
                result.push(goal);
              } else if (
                goal.recurrencePattern === recurrencePatternEnum.WEEKLY
              ) {
                if (
                  goal.days &&
                  goal.days.includes(moment(date).isoWeekday())
                ) {
                  result.push(goal);
                }
              } else if (
                goal.recurrencePattern === recurrencePatternEnum.MONTHLY
              ) {
                if (
                  moment(goal.startDate.toDate()).date() === moment(date).date()
                ) {
                  result.push(goal);
                }
              } else if (
                goal.recurrencePattern === recurrencePatternEnum.YEARLY
              ) {
                if (
                  moment(goal.startDate.toDate()).date() ===
                    moment(date).date() &&
                  moment(goal.startDate.toDate()).month() ===
                    moment(date).month()
                ) {
                  result.push(goal);
                }
              }
            }

            return result;
          }, []);

          dispatch(fetchRecurrentGoalsSuccess(recurrentGoals));
          dispatch(setLoading(false));
        },
        (error) => {
          dispatch(setLoading(false));
          captureException("Error fetching recurrent goals onSnapshot", error);
          console.log("Error fetching recurrent goals onSnapshot: " + error);
        }
      );
      unsubscribers.push(recurrentGoalsSubscriber);
      unsubscribersRecurrentGoals.push(recurrentGoalsSubscriber);
    } catch (error) {
      dispatch(setLoading(false));
      captureException("Error fetching recurrent goals", error);
      console.log("Error fetching recurrent goals: " + error);
    }
  };

export const fetchYesterdayRecurrentGoals = () => (dispatch) => {
  try {
    const userUid = auth.currentUser.uid;

    const date = moment().subtract(1, "days");

    dispatch(setLoadingYesterdayRecurrentGoals(true));
    dispatch(fetchYesterdayRecurrentGoalsSuccess([]));

    // Create a query for retrieving goals based on the given date
    const yesterdayRecurrentGoalsQuery = firestore
      .collection("recurrentGoals")
      .doc(userUid)
      .collection("recurrentGoals")
      .where(
        "startDate",
        "<=",
        moment(date).startOf("day").utcOffset(0, true).toDate()
      );

    // Create a snapshot listener for the query
    const yesterdayRecurrentGoalsSubscriber =
      yesterdayRecurrentGoalsQuery.onSnapshot(
        (snapshot) => {
          // Map the documents to goals array with necessary transformations
          const recurrentGoals = snapshot.docs.reduce(function (result, doc) {
            const data = doc.data();

            const getDate = (field) =>
              moment(data[field]?.toDate && data[field].toDate())
                .utc()
                .utcOffset(moment().utcOffset(), true);

            const goal = {
              ...data,
              id: doc.id,
              ...(data?.date && { date: getDate("date") }),
              ...(data?.startDate && { startDate: getDate("startDate") }),
              ...(data?.endDate && { endDate: getDate("endDate") }),
            };

            // First, check yesterday is not included in goal's skippedDates
            if (
              !goal.skippedDates ||
              (goal.skippedDates &&
                !goal.skippedDates.includes(moment(date).format("YYYYMMDD")))
            ) {
              // Now apply rest of conditionals to decide if goal should be taken into account
              if (
                !goal.endDate ||
                moment(
                  goal.endDate && goal.endDate.toDate && goal.endDate.toDate()
                ).isSameOrAfter(moment(date))
              ) {
                // Check if goal does not have endDate or it ends in a date bigger than yesterday
                if (goal.recurrencePattern === recurrencePatternEnum.DAILY) {
                  result.push(goal);
                } else if (
                  goal.recurrencePattern === recurrencePatternEnum.WEEKLY
                ) {
                  if (
                    goal.days &&
                    goal.days.includes(moment(date).isoWeekday())
                  ) {
                    result.push(goal);
                  }
                } else if (
                  goal.recurrencePattern === recurrencePatternEnum.MONTHLY
                ) {
                  if (
                    moment(goal.startDate.toDate()).date() ===
                    moment(date).date()
                  ) {
                    result.push(goal);
                  }
                } else if (
                  goal.recurrencePattern === recurrencePatternEnum.YEARLY
                ) {
                  if (
                    moment(goal.startDate.toDate()).date() ===
                      moment(date).date() &&
                    moment(goal.startDate.toDate()).month() ===
                      moment(date).month()
                  ) {
                    result.push(goal);
                  }
                }
              }
            }

            return result;
          }, []);

          dispatch(fetchYesterdayRecurrentGoalsSuccess(recurrentGoals));
          dispatch(setLoadingYesterdayRecurrentGoals(false));
        },
        (error) => {
          dispatch(setLoadingYesterdayRecurrentGoals(false));
          captureException(
            "Error fetching yesterday recurrent goals onSnapshot",
            error
          );
          console.log(
            "Error fetching yesterday recurrent goals onSnapshot: " + error
          );
        }
      );
    unsubscribers.push(yesterdayRecurrentGoalsSubscriber);
  } catch (error) {
    dispatch(setLoadingYesterdayRecurrentGoals(false));
    captureException("Error fetching yesterday recurrent goals", error);
    console.log("Error fetching yesterday recurrent goals: " + error);
  }
};

// This is used to close onSnapshots every time the date is changed
export const unsubscribeRecurrentGoals = () => {
  unsubscribersRecurrentGoals.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribersRecurrentGoals = [];
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeRecurrentGoalActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
