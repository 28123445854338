import React from "react";
import { Link } from "react-router-dom";

// Components
import { useTheme, Badge } from "@mui/material";
import { IoSettings } from "react-icons/io5";

// Assets
import { ReactComponent as GoalsIcon } from "../../assets/svg/goals.svg";
import { ReactComponent as TargetsIcon } from "../../assets/svg/targets.svg";
import { ReactComponent as MilestonesIcon } from "../../assets/svg/milestones.svg";
import { ReactComponent as CoachIcon } from "../../assets/svg/coach.svg";

// Own api
import { ScreenNames } from "../../api/constants";
import "./style.scss";

const NavBarMobile = (props) => {
  const theme = useTheme();

  return (
    <div
      className="bottom-tab"
      style={{
        backgroundColor: theme.palette.secondaryBackground.main,
        borderTop: theme.palette.gray.contrast,
      }}
    >
      <ul>
        <li style={{ cursor: "pointer" }}>
          <Link to={"/"}>
            <span
              onClick={() => props.setCurrentScreen(ScreenNames.GOALS_SCREEN)}
            >
              <Badge
                variant="standard"
                color="error"
                invisible={
                  props.haveToSubmitYesterday &&
                  (props.yesterdayGoals?.length > 0 ||
                    props.yesterdayRecurrentGoals?.length)
                    ? false
                    : true
                }
                badgeContent="1"
              >
                {props.currentScreen === ScreenNames.GOALS_SCREEN ? (
                  <GoalsIcon
                    height={25}
                    width={25}
                    fill={theme.palette.primary.main}
                  />
                ) : (
                  <GoalsIcon
                    height={25}
                    width={25}
                    fill={theme.palette.gray.dark}
                  />
                )}
                <label
                  style={{
                    color:
                      props.currentScreen === ScreenNames.GOALS_SCREEN
                        ? theme.palette.primary.main
                        : theme.palette.gray.dark,
                  }}
                >
                  Goals
                </label>
              </Badge>
            </span>
          </Link>
        </li>

        <li style={{ cursor: "pointer" }}>
          <Link to={"targets"}>
            <span
              onClick={() => props.setCurrentScreen(ScreenNames.TARGETS_SCREEN)}
            >
              <Badge
                variant="standard"
                color="error"
                invisible={
                  props.haveToSubmitLastMonth && props.lastMonthHasTargets
                    ? false
                    : true
                }
                badgeContent={1}
              >
                {props.currentScreen === ScreenNames.TARGETS_SCREEN ? (
                  <TargetsIcon
                    height={25}
                    width={25}
                    fill={theme.palette.primary.main}
                  />
                ) : (
                  <TargetsIcon
                    height={25}
                    width={25}
                    fill={theme.palette.gray.dark}
                  />
                )}
              </Badge>
              <label
                style={{
                  color:
                    props.currentScreen === ScreenNames.TARGETS_SCREEN
                      ? theme.palette.primary.main
                      : theme.palette.gray.dark,
                }}
              >
                Targets
              </label>
            </span>
          </Link>
        </li>

        <li style={{ cursor: "pointer" }}>
          <Link to={"milestones"}>
            <span
              onClick={() =>
                props.setCurrentScreen(ScreenNames.MILESTONES_SCREEN)
              }
            >
              <Badge
                variant="standard"
                color="error"
                invisible={props.unreadMilestones ? false : true}
                badgeContent={props.unreadMilestones}
              >
                {props.currentScreen === ScreenNames.MILESTONES_SCREEN ? (
                  <MilestonesIcon
                    height={25}
                    width={25}
                    fill={theme.palette.primary.main}
                  />
                ) : (
                  <MilestonesIcon
                    height={25}
                    width={25}
                    fill={theme.palette.gray.dark}
                  />
                )}
              </Badge>
              <label
                style={{
                  color:
                    props.currentScreen === ScreenNames.MILESTONES_SCREEN
                      ? theme.palette.primary.main
                      : theme.palette.gray.dark,
                }}
              >
                Milestones
              </label>
            </span>
          </Link>
        </li>

        <li style={{ cursor: "pointer" }}>
          <Link to={"settings"}>
            <span
              onClick={() =>
                props.setCurrentScreen(ScreenNames.SETTINGS_SCREEN)
              }
            >
              {props.currentScreen === ScreenNames.SETTINGS_SCREEN ? (
                <IoSettings
                  style={{ height: 25, width: 25 }}
                  color={theme.palette.primary.main}
                />
              ) : (
                <IoSettings
                  style={{ height: 25, width: 25 }}
                  color={theme.palette.gray.dark}
                />
              )}
              <label
                style={{
                  color:
                    props.currentScreen === ScreenNames.SETTINGS_SCREEN
                      ? theme.palette.primary.main
                      : theme.palette.gray.dark,
                }}
              >
                Settings
              </label>
            </span>
          </Link>
        </li>

        <li style={{ cursor: "pointer" }}>
          <Link to={"coach"}>
            <span
              onClick={() => {
                props.setCurrentScreen(ScreenNames.CHAT_SCREEN);
              }}
            >
              <Badge
                variant="standard"
                color="error"
                invisible={props.unreadCoachMessages ? false : true}
                badgeContent={props.unreadCoachMessages}
              >
                {props.currentScreen === ScreenNames.CHAT_SCREEN ? (
                  <CoachIcon
                    height={25}
                    width={25}
                    fill={theme.palette.primary.main}
                  />
                ) : props.unreadCoachMessages ? (
                  <img
                    className="coach-img"
                    src={props.coachAvatar}
                    alt="coach"
                  />
                ) : (
                  <CoachIcon
                    height={25}
                    width={25}
                    fill={theme.palette.gray.dark}
                  />
                )}
              </Badge>
              {!props.unreadCoachMessages ? (
                <label
                  style={{
                    color:
                      props.currentScreen === ScreenNames.CHAT_SCREEN
                        ? theme.palette.primary.main
                        : theme.palette.gray.dark,
                  }}
                >
                  Coach
                </label>
              ) : null}
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default React.memo(NavBarMobile);
