import { firestore, auth } from "../../api/firebase";
import moment from "moment";
import "moment-timezone";
import { captureException } from "@sentry/react";

import {
  FETCH_GOALS,
  SET_LOADING_GOALS,
  FETCH_YESTERDAY_GOALS,
  SET_LOADING_YESTERDAY_GOALS,
} from "../types";

let unsubscribers = [];
let unsubscribersGoals = [];

const fetchGoalsSuccess = (goals) => ({
  type: FETCH_GOALS,
  payload: goals,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_GOALS,
  payload: isLoading,
});

const fetchYesterdayGoalsSuccess = (goals) => ({
  type: FETCH_YESTERDAY_GOALS,
  payload: goals,
});

const setLoadingYesterdayGoals = (isLoading) => ({
  type: SET_LOADING_YESTERDAY_GOALS,
  payload: isLoading,
});

// TODO consider if pagination is necessary, because Firebase suggests to avoid frequently churning listeners
// https://firebase.google.com/docs/firestore/best-practices#realtime_updates
// But this guy says that it won't cause technical issues (just not the cleanest approach)
// https://stackoverflow.com/a/66979317/8111691

export const fetchGoals =
  (date = moment()) =>
  (dispatch) => {
    try {
      const userUid = auth.currentUser.uid;

      dispatch(setLoading(true));
      dispatch(fetchGoalsSuccess([]));

      const goalsQuery = firestore
        .collection("goals")
        .doc(userUid)
        .collection("goals")
        .where(
          "date",
          ">=",
          moment(date).startOf("day").utcOffset(0, true).toDate()
        )
        .where(
          "date",
          "<=",
          moment(date).endOf("day").utcOffset(0, true).toDate()
        );

      // Create a snapshot listener for the query
      const goalsSubscriber = goalsQuery.onSnapshot(
        (snapshot) => {
          // Map the documents to goals array with necessary transformations
          const goals = snapshot.docs.map((doc) => {
            const data = doc.data();

            const getDate = (field) => {
              return moment(data[field]?.toDate && data[field].toDate())
                .utc()
                .utcOffset(moment().utcOffset(), true);
            };

            return {
              ...data,
              id: doc.id,
              originalGoalId: data?.id || null,
              ...(data?.date && { date: getDate("date") }),
              ...(data?.startDate && { startDate: getDate("startDate") }),
              ...(data?.endDate && { endDate: getDate("endDate") }),
            };
          });

          dispatch(fetchGoalsSuccess(goals));
          dispatch(setLoading(false));
        },
        (error) => {
          dispatch(setLoading(false));
          captureException("Error fetching goals onSnapshot", error);
          console.log("Error fetching goals onSnapshot: " + error);
        }
      );
      unsubscribers.push(goalsSubscriber);
      unsubscribersGoals.push(goalsSubscriber);
    } catch (error) {
      dispatch(setLoading(false));
      captureException("Error fetching goals", error);
      console.log("Error fetching goals: " + error);
    }
  };

export const fetchYesterdayGoals = () => (dispatch) => {
  try {
    const userUid = auth.currentUser.uid;

    const date = moment().subtract(1, "days");

    dispatch(setLoadingYesterdayGoals(true));
    dispatch(fetchYesterdayGoalsSuccess([]));

    const yesterdayGoalsQuery = firestore
      .collection("goals")
      .doc(userUid)
      .collection("goals")
      .where(
        "date",
        ">=",
        moment(date).startOf("day").utcOffset(0, true).toDate()
      )
      .where(
        "date",
        "<=",
        moment(date).endOf("day").utcOffset(0, true).toDate()
      );

    // Create a snapshot listener for the query
    const yesterdayGoalsSubscriber = yesterdayGoalsQuery.onSnapshot(
      (snapshot) => {
        // Map the documents to goals array with necessary transformations
        const yesterdayGoals = snapshot.docs.map((doc) => {
          const data = doc.data();

          const getDate = (field) =>
            moment(data[field]?.toDate && data[field].toDate())
              .utc()
              .utcOffset(moment().utcOffset(), true);

          return {
            ...data,
            id: doc.id,
            ...(data?.date && { date: getDate("date") }),
            ...(data?.startDate && { startDate: getDate("startDate") }),
            ...(data?.endDate && { endDate: getDate("endDate") }),
          };
        });
        dispatch(fetchYesterdayGoalsSuccess(yesterdayGoals));
        dispatch(setLoadingYesterdayGoals(false));
      },
      (error) => {
        dispatch(setLoadingYesterdayGoals(false));
        captureException("Error fetching yesterday goals onSnapshot", error);
        console.log("Error fetching yesterday goals onSnapshot: " + error);
      }
    );
    unsubscribers.push(yesterdayGoalsSubscriber);
  } catch (error) {
    dispatch(setLoadingYesterdayGoals(false));
    captureException("Error fetching yesterday goals", error);
    console.log("Error fetching yesterday goals: " + error);
  }
};

// This is used to close onSnapshots every time the date is changed while navigating goals
export const unsubscribeGoals = () => {
  unsubscribersGoals.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribersGoals = [];
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeGoalActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
