import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

import { useTheme, Button, Checkbox, LinearProgress } from "@mui/material";
import { FaCheckCircle, FaRegCircle, FaAngleRight } from "react-icons/fa";

// Firestore
import { addRecurrentGoal } from "../../api/firestore/recurrentGoals";

// Assets
import { ReactComponent as Background } from "../../assets/svg/background.svg";

// Api
import { Devices, recurrencePatternEnum } from "../../api/constants";
import { useWindowSize } from "../../api/helper";
import { en } from "../../api/languages/en";
import "./style.scss";

const Screen6 = (props) => {
  const theme = useTheme();
  const [windowWidth] = useWindowSize();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  // Set starting goals corresponding to tag attached to user's quiz. If no quiz, set default
  const [startingGoals, setStartingGoals] = useState(
    props.tags?.find((tag) => tag.id === props.quiz?.tag || "default")
      ?.startingGoals
  );

  const onItemPressed = (item) => {
    // Set new startingGoals state
    if (startingGoals.filter((goal) => goal.isSelected).length < 3) {
      // If less than 3 have been selected
      setStartingGoals(
        [...startingGoals].map((el, index) => {
          if (index === item.id) {
            return {
              ...el,
              isSelected: !item.isSelected,
            };
          } else {
            return {
              ...el,
            };
          }
        })
      );
    } else {
      // If 3 already selected, deselect it
      if (item.isSelected) {
        setStartingGoals(
          [...startingGoals].map((el, index) => {
            if (index === item.id) {
              return {
                ...el,
                isSelected: false,
              };
            } else {
              return {
                ...el,
              };
            }
          })
        );
      } else {
        toast.error("Maximum allowed is 3");
      }
    }
  };

  const handlePress = useCallback(() => {
    startingGoals?.length &&
      startingGoals.map(async (item, index) => {
        if (item.isSelected) {
          await addRecurrentGoal({
            name: item.name,
            position: index,
            isDone: false,
            tag: item.tag,
            description: item.description,
            startDate: moment(),
            recurrencePattern: recurrencePatternEnum.DAILY,
            days: [],
            endDate: null,
            isRolledOver: item.isRolledOver,
          });
        }
      });

    props.tutorialRef?.current?.go(7);
  }, [props.tutorialRef, startingGoals]);

  return (
    <div className="tutorial-container">
      <div className="starting-goals-container">
        <div
          className="top-image"
          style={{
            height:
              currentDevice === Devices.PHONE ? 529 * (windowWidth / 375) : 529,
          }}
        >
          {currentDevice === Devices.PHONE ? (
            <Background
              width="100%"
              height={529 * (windowWidth / 375)}
              style={{
                position: "absolute",
                zIndex: -999,
              }}
            />
          ) : (
            <Background
              width={375}
              height={529}
              style={{
                position: "absolute",
                zIndex: -999,
              }}
            />
          )}

          <div className="repeat-options-container">
            {startingGoals?.length &&
              startingGoals.map((item, index) => (
                <div
                  key={`repeatOptionContainer-${index}`}
                  className="repeat-option"
                  style={{ backgroundColor: theme.palette.light.main }}
                  onClick={() => onItemPressed({ ...item, id: index })}
                >
                  <Checkbox
                    color="primary"
                    checked={item.isSelected}
                    // onChange={(e) => {
                    //   onItemPressed({ ...item, id: index });
                    // }}
                    checkedIcon={<FaCheckCircle size={24} />}
                    icon={
                      <FaRegCircle
                        size={24}
                        style={{
                          color: theme.palette.gray.main,
                        }}
                      />
                    }
                  />
                  <label
                    className="repeat-option-text"
                    style={{ color: theme.palette.primary.main }}
                  >
                    {item.name}
                  </label>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="body">
        <label className="text" style={{ color: theme.palette.text.main }}>
          {en.TUTORIAL_6}
        </label>
      </div>

      <div className="footer">
        <LinearProgress
          variant="determinate"
          color="tertiary"
          value={(6 * 100) / 7}
          style={{ width: 150 }}
        />
        {startingGoals?.length &&
        startingGoals.filter((goal) => goal.isSelected).length ? (
          <Button
            id="right-click-btn"
            variant="contained"
            color="quaternary"
            onClick={handlePress}
            sx={{ height: 50, width: 50 }}
          >
            <FaAngleRight size={24} />
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(Screen6);
