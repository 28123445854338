import React from "react";
import moment from "moment";

import { useSelector } from "react-redux";

import { FaRegStar, FaStar } from "react-icons/fa";
import { useTheme, IconButton } from "@mui/material";

import { updateUser } from "../../api/firestore/users";
import { updateCoach } from "../../api/firestore/coaches";

import tap_coach_button from "../../assets/sounds/tap_coach_button.wav";
import { playSound } from "../../api/filesHandler";
import { colors, USER_TYPE } from "../../api/constants";
import "./style.scss";

const ChatHeader = ({
  userId,
  userGuide,
  userType,
  userCreatedTime,
  tour,
  coachId,
  coachName,
  coachAvatar,
  coachAvatarWeekend,
  coachStatus,
  toggleMessagesFilter,
  showStarredMessages,
  setIsCoachInfoOverlayVisible,
  setIsCoachStatusOverlayVisible,
}) => {
  const theme = useTheme();

  const isSoundEffectsEnabled = useSelector(
    (state) => state.local.isSoundEffectsEnabled
  );

  const onCoachClicked = () => {
    if (userGuide?.length) {
      if (!userGuide.includes("chat")) {
        updateUser({
          guide: [...userGuide, "chat"],
        });
      }
    } else {
      updateUser({
        guide: ["chat"],
      });
    }

    tour.hide();

    if (
      userType &&
      userType !== USER_TYPE.INACTIVE &&
      coachStatus &&
      (!coachStatus?.seen?.length ||
        !coachStatus.seen.find((el) => el === userId))
    ) {
      setIsCoachStatusOverlayVisible(true);
      // Mark coach status as seen
      updateCoach(coachId, {
        status: { seen: [...(coachStatus?.seen ?? []), userId] },
      });
    } else {
      setIsCoachInfoOverlayVisible(true);
      if (isSoundEffectsEnabled) {
        playSound(tap_coach_button);
      }
    }
  };

  return (
    <>
      <div
        className="headerContainer"
        style={{ backgroundColor: theme.palette.secondaryBackground.main }}
      >
        <button
          className="headerButton"
          onClick={() => {
            onCoachClicked();
          }}
        >
          <div
            className={
              userType &&
              userType !== USER_TYPE.INACTIVE &&
              coachStatus &&
              (!coachStatus?.seen?.length ||
                !coachStatus.seen.find((el) => el === userId))
                ? theme.palette.mode === "dark"
                  ? "coach-img-container-with-border-dark-mode"
                  : "coach-img-container-with-border-light-mode"
                : "coach-img-container"
            }
            // style={
            //   {
            //     // If corresponds, show color and if not, 'transparent'
            //     // BORDER FOR STATUS, ALERNATIVE 1
            //     background: `linear-gradient(${theme.palette.secondaryBackground.main}, ${theme.palette.secondaryBackground.main}) padding-box,
            //           linear-gradient(to right, ${colors.milestoneGradientFirst}, ${colors.milestoneGradientSecond}) border-box`,
            //   }
            // }
          >
            {/* Show weekend coach's avatar only if:
            - Client subscribed 7 or more days ago
            - It's weekend (Sat or Sun) */}
            <img
              className="coach-img"
              src={
                userType !== undefined &&
                userCreatedTime &&
                moment(
                  userCreatedTime?.toDate && userCreatedTime.toDate()
                ).isBefore(
                  moment().startOf("day").subtract(7, "days"),
                  "day"
                ) &&
                moment().isoWeekday() === (6 || 7)
                  ? coachAvatarWeekend
                  : coachAvatar
              }
              alt="coach"
            />
          </div>
          <div className="textContainer">
            <label
              className="coachName"
              style={{ color: theme.palette.text.main }}
            >
              {coachName}
            </label>
            <label
              className="textExtra"
              style={{ color: theme.palette.text.main }}
            >
              Your coach
            </label>
          </div>
        </button>
        <div className="star-btn-container">
          <IconButton
            size="medium"
            onClick={() => toggleMessagesFilter("starred")}
          >
            {showStarredMessages ? (
              <FaStar
                color={colors.milestoneGradientSecond}
                size={22}
                className="add-files-icon"
              />
            ) : (
              <FaRegStar
                color={theme.palette.gray.main}
                size={22}
                className="add-files-icon"
              />
            )}
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default React.memo(ChatHeader);
