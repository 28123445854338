import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { captureException } from "@sentry/react";

import { useTheme, Button, TextField, LinearProgress } from "@mui/material";
import { FaCheck } from "react-icons/fa";

// Firestore
import { addTarget } from "../../api/firestore/targets";
import { addMonth, isMonthCreated } from "../../api/firestore/months";
import { addWelcomeMessage } from "../../api/firestore/chats";
import {
  deletePreregisteredUser,
  getPreregisteredUser,
} from "../../api/firestore/users";
import { updateUser } from "../../api/firestore/users";

// Analytics
import { analytics } from "../../api/firebase";

// Assets
import { ReactComponent as Background } from "../../assets/svg/background.svg";

// Api
import { Devices, USER_TYPE } from "../../api/constants";
import {
  useWindowSize,
  sendOnboardingSlackNotification,
} from "../../api/helper";
import { en } from "../../api/languages/en";
import "./style.scss";

const Screen7 = (props) => {
  const theme = useTheme();
  const [windowWidth] = useWindowSize();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  const [targetName, setTargetName] = useState("");

  const timeToFinishTutorial = () => {
    var startDate = moment(
      props.user.createdTime?.toDate && props.user.createdTime.toDate()
    );
    var endDate = moment();
    var diffTime = moment(endDate).diff(startDate);

    var duration = moment.duration(diffTime);
    var days = duration.days(),
      hrs = duration.hours(),
      mins = duration.minutes(),
      secs = duration.seconds();

    return (
      (days ? days + " days " : "") +
      hrs +
      " hrs " +
      mins +
      " mins " +
      secs +
      " secs"
    );
  };

  const handlePress = useCallback(async () => {
    const monthDate =
      moment().startOf("day").format("YYYYMMDD") <
      moment().endOf("month").subtract(6, "days").format("YYYYMMDD")
        ? moment()
        : moment().add(1, "month"); // If today is one of the last 7 days of the month, default to next month,

    await addTarget({
      name: targetName.trim(),
      isDone: false,
      tag: null,
      description: "",
      date: monthDate,
    });

    const isMonthToRollOverTargetsToCreated = await isMonthCreated(monthDate);
    if (!isMonthToRollOverTargetsToCreated) {
      addMonth(monthDate);
    }

    const preregisteredUser = await getPreregisteredUser(props.user.email);

    if (
      props.user?.coach &&
      props.user?.type &&
      props.user?.type !== USER_TYPE.INACTIVE
    ) {
      // Only send notification if client:
      // has coach
      // has type and it's different from "inactive"

      // -------------------- Send to general channel (#onboarding) --------------------
      sendOnboardingSlackNotification(
        `✅ FINISHED TUTORIAL: ${timeToFinishTutorial()} 
        
${
  preregisteredUser?.customMessage
    ? "👋 welcome message was customized by coach"
    : "🥺 welcome message wasn't customized by coach"
} 
        
New client: ${props.user?.name}
Coach: ${props.coach?.name}

<https://goalswon-coaching-app.web.app/client/${
          props.user?.id
        } | Send a chat message>`
      );

      // -------------------- Send to coach channel (#coach-X-new-client) --------------------
      if (props.coach?.slackNewClients) {
        fetch(props.coach.slackNewClients, {
          method: "POST",
          body: JSON.stringify({
            text: `✅ FINISHED TUTORIAL: ${timeToFinishTutorial()} 
        
${
  preregisteredUser?.customMessage
    ? "👋 welcome message was customized by coach"
    : "🥺 welcome message wasn't customized by coach"
} 
                  
New client: ${props.user?.name}
          
<https://goalswon-coaching-app.web.app/client/${
              props.user?.id
            } | Send a chat message>`,
          }),
        })
          .then((response) => {
            // console.log("response: ", response.json());
          })
          .then((result) => {
            // console.log("result: ", result.json());
          })
          .catch((error) => {
            captureException(
              "Error while sending finished tutorial slack notification",
              error
            );
            console.log(
              "Error while sending finished tutorial slack notification: " +
                error
            );
          });
      }
    }

    if (preregisteredUser?.customMessage) {
      updateUser({
        welcomeMessageCustomizedByCoach: moment(),
      });
    }

    const welcomeMessageToAdd = preregisteredUser
      ? preregisteredUser?.type &&
        preregisteredUser?.type !== USER_TYPE.INACTIVE
        ? preregisteredUser?.customMessage || props.coach?.defaultWelcomeMessage // Pre-registered premium/EA/Free/etc user
        : props.coach?.defaultWelcomeMessageWithCTA // Pre-registered with no type or inactive user
      : props.coach?.defaultWelcomeMessageWithCTA; // Free user

    if (welcomeMessageToAdd) {
      addWelcomeMessage(
        props.coach?.id,
        props.coach?.name,
        welcomeMessageToAdd
      );
    }

    // Delete the no longer used pre-registered user from db
    if (preregisteredUser) {
      await deletePreregisteredUser(props.user.email);
    }

    updateUser({ finishedTutorial: moment() }); // WARNING - Change if tutorial changes

    analytics.logEvent("tutorial_complete"); // Log analytics event || WARNING - Attention, change if tutorial changes
  }, [
    targetName,
    props.user.email,
    props.user?.coach,
    props.user?.type,
    props.user?.name,
    props.user?.id,
    props.coach?.defaultWelcomeMessage,
    props.coach?.defaultWelcomeMessageWithCTA,
    props.coach?.name,
    props.coach?.slackNewClients,
    props.coach?.id,
    timeToFinishTutorial,
  ]);

  // Keyboard
  const keyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handlePress();
    }
  };

  return (
    <div className="tutorial-container">
      <div className="starting-goals-container">
        <div
          className="top-image"
          style={{
            height:
              currentDevice === Devices.PHONE ? 529 * (windowWidth / 375) : 529,
          }}
        >
          {currentDevice === Devices.PHONE ? (
            <Background
              width="100%"
              height={529 * (windowWidth / 375)}
              style={{
                position: "absolute",
                zIndex: -999,
              }}
            />
          ) : (
            <Background
              width={375}
              height={529}
              style={{
                position: "absolute",
                zIndex: -999,
              }}
            />
          )}

          <div className="addItemContainer">
            <div
              className="addItemInputContainer"
              style={{ backgroundColor: theme.palette.light.main }}
            >
              <label className="addItemText">
                What’s a target you’d like to achieve within a month?
              </label>
              <div className="addItemInputAndSendContainer">
                <TextField
                  variant="standard"
                  type="text"
                  placeholder={'eg - "Launch project Phoenix"'}
                  value={targetName}
                  autoComplete="off"
                  fullWidth
                  margin="dense"
                  autoFocus
                  required
                  onChange={(e) => setTargetName(e.target.value)}
                  onKeyDown={(e) => {
                    keyDownHandler(e);
                  }}
                  inputProps={{ maxLength: 140 }}
                  sx={{
                    input: { color: theme.palette.dark.main },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: theme.palette.gray.main,
                    },
                    "& .MuiInput-underline:hover": {
                      borderBottomColor: theme.palette.gray.main,
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: theme.palette.gray.main,
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: theme.palette.gray.main,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="body">
        <label className="text" style={{ color: theme.palette.text.main }}>
          {en.TUTORIAL_7}
          {
            moment().startOf("day").format("YYYYMMDD") <
            moment().endOf("month").subtract(6, "days").format("YYYYMMDD")
              ? moment().format("MMMM")
              : moment().add(1, "month").format("MMMM") // If today is one of the last 7 days of the month, default to next month,
          }
        </label>
      </div>

      <div className="footer">
        <LinearProgress
          variant="determinate"
          color="tertiary"
          value={(7 * 100) / 7}
          style={{ width: 150 }}
        />
        {targetName !== "" ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handlePress}
            sx={{ height: 50, width: 50 }}
          >
            <FaCheck size={24} />
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(Screen7);
