import { firestore, auth } from "../firebase";
import moment from "moment";
import "moment-timezone";
import { captureException } from "@sentry/react";

// Firestore
import { deleteMessage } from "./chats";
import { deleteGoal, getOriginalGoalId } from "./goals";

// Own api
import { getTimestamp } from "../helper";
import { recurrencePatternEnum } from "../constants";

export const addDay = (date = moment()) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date).format("YYYYMMDD");

    const day = {
      isSubmitted: false,
      date: getTimestamp(moment(date).startOf("day").utcOffset(0, true)),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new day
    const dayRef = firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .doc(id);
    batch.set(dayRef, day, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting day transaction", error);
      console.error("Error while commiting day transaction: " + error);
    });
  } catch (error) {
    captureException("Error while adding day", error);
    console.error("Error while adding day: " + error);
  }
};

export const updateDay = (date = moment(), dayReceived) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date).format("YYYYMMDD");

    const day = {
      clientComment: dayReceived.clientComment,
      date: getTimestamp(moment(date).startOf("day").utcOffset(0, true)),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new day
    const dayRef = firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .doc(id);
    batch.set(dayRef, day, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting day transaction", error);
      console.error("Error while commiting day transaction: " + error);
    });
  } catch (error) {
    captureException("Error while updating day", error);
    console.error("Error while updating day: " + error);
  }
};

export const undoSubmitResults = async (date, day) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date).format("YYYYMMDD");

    const unsubmittedDay = {
      isSubmitted: false, // Set back to false
      date: getTimestamp(moment(date).startOf("day").utcOffset(0, true)),
      movedGoals: [], // Set movedGoals back to none, because none would have been moved
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new day
    const dayRef = firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .doc(id);
    batch.set(dayRef, unsubmittedDay, { merge: true });

    // ---- Delete messages ----
    if (day?.messageId) {
      deleteMessage({ id: day.messageId }, false);
    }
    if (day?.imagesId?.length) {
      day.imagesId.forEach((imageId) => {
        deleteMessage({ id: imageId }, false);
      });
    }

    // ---- Delete moved goals ----
    if (day?.movedGoals?.length) {
      day.movedGoals.forEach(async (movedGoal) => {
        try {
          const originalGoalId = await getOriginalGoalId(movedGoal);

          if (originalGoalId) {
            firestore
              .collection("goals")
              .doc(userUid)
              .collection("goals")
              .where("date", ">", moment(day.date).utcOffset(0, true).toDate())
              .where("id", "==", originalGoalId)
              .get()
              .then(async (goalsToDelete) => {
                goalsToDelete.docs.map((goal) => deleteGoal({ id: goal.id }));
              })
              .catch((error) => {
                captureException("Error fetching goals to delete", error);
                console.log("Error fetching goals to delete: " + error);
              });
          }
        } catch (error) {
          captureException("Error while fetching goals to delete", error);
          console.error("Error while fetching goals to delete: " + error);
        }
      });
    }

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting undo submit day transaction",
        error
      );
      console.error(
        "Error while commiting undo submit day transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while submitting undo submit day", error);
    console.error("Error while submitting undo submit day: " + error);
  }
};

export const autoSubmit = async () => {
  try {
    const userUid = auth.currentUser.uid;
    const yesterday = moment()
      .subtract(1, "days")
      .startOf("day")
      .utcOffset(0, true)
      .toDate();

    // Get unsubmitted days older than yesterday
    const unsubmittedDaysQuery = await firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .where("isSubmitted", "==", false)
      .where("date", "<", yesterday)
      .get();

    const unsubmittedDays = unsubmittedDaysQuery.docs.map((doc) => {
      return {
        id: doc.id,
        date: moment(doc.data().date?.toDate && doc.data().date.toDate())
          .utc()
          .utcOffset(moment().utcOffset(), true),
      };
    });

    if (unsubmittedDays.length === 0) {
      console.log("No unsubmitted days found.");
      return;
    }

    // Start transaction
    const batch = firestore.batch();

    // Check day goals are going to move to
    // 1) If today is not submitted, roll over goals to today.
    // 2) If today is already submitted, roll over goals to tomorrow.
    let dayToRollOverGoalsTo;
    const isTodaySubmitted = await isDaySubmitted();
    dayToRollOverGoalsTo = moment()
      .add(isTodaySubmitted ? 1 : 0, "days")
      .startOf("day");
    // Create day to which goals will move to, if it's not created already.
    const isDayToRollOverGoalsToCreated = await isDayCreated(
      dayToRollOverGoalsTo
    );
    if (!isDayToRollOverGoalsToCreated) {
      addDay(dayToRollOverGoalsTo);
    }

    // Loop through unsubmitted days and get their instantiated undone goals
    for (const day of unsubmittedDays) {
      const goalsQuery = await firestore
        .collection("goals")
        .doc(userUid)
        .collection("goals")
        .where("isDone", "==", false)
        .where("date", ">=", moment(day.date).utcOffset(0, true).toDate())
        .where(
          "date",
          "<",
          moment(day.date).add(1, "days").utcOffset(0, true).toDate()
        )
        .get();

      const goals = goalsQuery.docs
        .map((doc) => {
          // Here it's not necessary to convert (like in fetchGoals()) because we don't handle using moment or anything. We just get them in timestamp, and reupload without viewing or modifying anything

          return {
            ...doc.data(),
            id: doc.data()?.id || doc.id, // doc.data().id if goal is copy of original (rolled more than once). Or goal.id if goal IS the original (first time rolled)
          };
        })
        .filter(
          // Keep goals that:
          // - Are "roll over"
          // - Don't come from recurrent
          // - Are not daily
          // - Are weekly and don't include today or tomorrow (if today is already submitted) as one of its repeating days
          (el) =>
            (el.isRolledOver === true || el.isRolledOver === undefined) &&
            (!el.fromRecurrentGoal ||
              (el.recurrencePattern !== recurrencePatternEnum.DAILY &&
                !(
                  el.recurrencePattern === recurrencePatternEnum.WEEKLY &&
                  el.days &&
                  el.days.includes(moment(dayToRollOverGoalsTo).isoWeekday())
                )))
        );

      if (goals.length > 0) {
        for (const goal of goals) {
          // BUG: THIS SHOULD ACTUALLY RUN BUT IT WON'T
          // BECAUSE IS TAPPING INTO GOALS COLLECTION (NOT RECURRENT) AND ALL GOALS HAVE A DATE
          // NECESSARY OR ELSE RECURRENT GOALS WON'T BE ROLLED OVER ON AUTOSUBMIT
          // A FIX WOULD BE TO INSTANTIATE A REGULAR GOAL ON THAT VERY SAME DAY, AND THIS CONDITIONAL WON'T BE NECESSARY
          // For goals that have been created in mobile app platform where data input allowes user to create recurrent goals from the beginning (with no date).
          // if (!item.date) {
          //   // Instantiate recurrent goal
          //   await addGoal({
          //     ...item,
          //     date: moment(),
          //     fromRecurrentGoal: item.id,
          //     isDone: false,
          //   });
          // }

          const newMovedGoalRef = firestore
            .collection("goals")
            .doc(userUid)
            .collection("goals")
            .doc();

          batch.set(newMovedGoalRef, {
            ...goal,
            date: getTimestamp(moment(dayToRollOverGoalsTo).utcOffset(0, true)),
          });

          if (goal.isPartiallyDone) {
            batch.update(firestore.doc(`users/${userUid}`), {
              lastTimeUsedPartiallyDoneGoals: getTimestamp(),
            });
          }
        }

        batch.update(firestore.doc(`days/${userUid}/days/${day.id}`), {
          isSubmitted: true,
          movedGoals: goals.map((goal) => goal.id),
          lastSubmissionType: "auto",
        });
      } else {
        batch.update(firestore.doc(`days/${userUid}/days/${day.id}`), {
          isSubmitted: true,
          lastSubmissionType: "auto",
        });
      }
    }

    await batch.commit();
    console.log("Day autosubmit completed.");
  } catch (error) {
    captureException("Error while autosubmitting day", error);
    console.error("Error while autosubmitting day: " + error);
  }
};

export const isDayCreated = async (date = moment()) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date)
      .startOf("day")
      .utcOffset(0, true)
      .format("YYYYMMDD");

    return await firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .doc(id)
      .get()
      .then((query) => {
        if (query.exists) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        captureException("Error checking if day is instantiated", error);
        console.log("Error checking if day is instantiated: " + error);
      });
  } catch (error) {
    captureException("Error checking if day is instantiated", error);
    console.log("Error checking if day is instantiated: " + error);
  }
};

export const isDaySubmitted = async (date = moment()) => {
  try {
    const userUid = auth.currentUser.uid;

    const startOfDay = moment(date).startOf("day").utcOffset(0, true).toDate();

    const dayQuery = await firestore
      .collection(`days/${userUid}/days`)
      .where("date", "==", startOfDay)
      .where("isSubmitted", "==", true)
      .limit(1)
      .get();

    return !dayQuery.empty;
  } catch (error) {
    captureException("Error while checking if day is submitted", error);
    console.log("Error while checking if day is submitted: " + error);
    return false;
  }
};

export const noDayAlreadySubmitted = async () => {
  try {
    const userUid = auth.currentUser.uid;

    return await firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .where("isSubmitted", "==", true)
      .limit(1)
      .get()
      .then(async (query) => {
        return query.empty;
      });
  } catch (error) {
    captureException(
      "Failed to check if there's any day already submitted",
      error
    );
    console.error(
      "Failed to check if there's any day already submitted: " + error
    );
  }
};
