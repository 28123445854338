import firebase from "firebase/compat/app";
import { firestore, auth } from "../firebase";
import { toast } from "react-toastify";
import moment from "moment";
import "moment-timezone";

import { addMessage, deleteMessage } from "./chats";

import { getTimestamp } from "../helper";
import { updateLastTimeActive, updateLastTimeGoalsInteraction } from "./users";

import { captureException } from "@sentry/react";

export const addTarget = async (
  targetReceived,
  id = null,
  showToast = false
) => {
  try {
    const userUid = auth.currentUser.uid;

    const { originalGoalId, ...targetDestructured } = targetReceived; // Destructuring

    const target = {
      ...targetDestructured,
      date: getTimestamp(
        moment(targetDestructured.date).startOf("month").utcOffset(0, true)
      ),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // WARNING - .doc(null) is not working, that's why conditional
    let targetRef = null;
    if (id) {
      targetRef = firestore
        .collection("targets")
        .doc(userUid)
        .collection("targets")
        .doc(id);
    } else {
      targetRef = firestore
        .collection("targets")
        .doc(userUid)
        .collection("targets")
        .doc();
    }
    batch.set(targetRef, { ...target, createdAt: getTimestamp() });

    // Increment targetsTotal
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        targetsTotal: firebase.firestore.FieldValue.increment(1),
        targetsDone: firebase.firestore.FieldValue.increment(
          target.isDone ? 1 : 0
        ),
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch
      .commit()
      .then(() => {
        showToast && toast.success("Target added!");
      })
      .catch((error) => {
        captureException("Error while commiting target transaction", error);
        console.error("Error while commiting target transaction: " + error);
      });

    updateLastTimeActive();
    updateLastTimeGoalsInteraction();

    return targetRef.id;
  } catch (error) {
    captureException("Error while adding target", error);
    console.error("Error while adding target: " + error);
  }
};

export const updateTarget = (targetReceived) => {
  try {
    const userUid = auth.currentUser.uid;

    const { id, originalGoalId, ...targetDestructured } = targetReceived; // Destructuring

    const target = {
      ...targetDestructured,
      date: getTimestamp(
        moment(targetDestructured.date).startOf("month").utcOffset(0, true)
      ),
      ...(targetDestructured.createdAt && {
        createdAt: getTimestamp(
          moment(
            targetDestructured?.createdAt?.toDate &&
              targetDestructured?.createdAt?.toDate()
          )
        ),
      }),
      ...(targetDestructured.updatedAt && {
        updatedAt: getTimestamp(
          moment(
            targetDestructured?.updatedAt?.toDate &&
              targetDestructured?.updatedAt?.toDate()
          )
        ),
      }),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Update target
    const targetRef = firestore
      .collection("targets")
      .doc(userUid)
      .collection("targets")
      .doc(id);
    batch.set(
      targetRef,
      { ...target, updatedAt: getTimestamp() },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting target update transaction",
        error
      );
      console.error(
        "Error while commiting target update transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while updating target", error);
    console.error("Error while updating target: " + error);
  }
};

export const sortTargets = async (targets) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    targets.forEach((target) => {
      const { id, ...targetWithoutId } = target;
      // Update target
      const targetRef = firestore
        .collection("targets")
        .doc(userUid)
        .collection("targets")
        .doc(id);
      batch.set(targetRef, targetWithoutId, { merge: true });
    });

    // ---- Finish transaction ----
    await batch.commit().catch((error) => {
      captureException(
        "Error while commiting target update transaction",
        error
      );
      console.error(
        "Error while commiting target update transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while updating target (sortTargets func)", error);
    console.error("Error while updating target (sortTargets func): " + error);
  }
};

export const deleteTarget = (target) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Delete target
    const targetRef = firestore
      .collection("targets")
      .doc(userUid)
      .collection("targets")
      .doc(target.id);
    batch.delete(targetRef, target);

    // Delete its chat message
    if (target.messageAddId) {
      deleteMessage({ id: target.messageAddId }, false);
    }

    // Decrement targetsTotal and targetsDone
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        targetsTotal: firebase.firestore.FieldValue.increment(-1),
        targetsDone: firebase.firestore.FieldValue.increment(
          target.isDone ? -1 : 0
        ),
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting delete target transaction",
        error
      );
      console.error(
        "Error while commiting delete target transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while deleting target", error);
    console.error("Error while deleting target: " + error);
  }
};

export const toggleTargetDone = (target) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Change target isDone value
    const targetRef = firestore
      .collection("targets")
      .doc(userUid)
      .collection("targets")
      .doc(target.id);
    batch.set(
      targetRef,
      {
        isDone: target.isDone,
        isPartiallyDone: target.isPartiallyDone,
        updatedAt: getTimestamp(),
        messageDoneId: target.messageDoneId || "",
      },
      { merge: true }
    );

    // Update targetsDone
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        targetsDone: firebase.firestore.FieldValue.increment(
          target.isDone ? 1 : -1
        ),
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting toggle target transaction",
        error
      );
      console.error(
        "Error while commiting toggle target transaction: " + error
      );
    });

    updateLastTimeActive();
    updateLastTimeGoalsInteraction();
  } catch (error) {
    captureException("Error while toggling target", error);
    console.error("Error while toggling target: " + error);
  }
};

export const undoDeleteTarget = async (targetReceived) => {
  try {
    const { id, updatedAt, ...target } = targetReceived;

    const message = `🎯 Committed to \n "${
      target.name
    }"\nby the end of ${moment(target.date).format("MMMM")}`;

    const messageAddId = await addMessage({
      text: message,
      isTargetAdded: moment(),
    });

    addTarget({ ...target, messageAddId }, id);
  } catch (error) {
    captureException("Error while undoing delete target", error);
    console.error("Error while undoing delete target: " + error);
  }
};

export const getOriginalTargetId = async (targetId) => {
  try {
    const userUid = auth.currentUser.uid;

    return await firestore
      .collection("targets")
      .doc(userUid)
      .collection("targets")
      .doc(targetId)
      .get()
      .then(async (query) => {
        let originalTargetId = null;

        if (query.exists) {
          originalTargetId = query.data().id || query.id;
        }

        return originalTargetId;
      });
  } catch (error) {
    captureException("Failed to get target", error);
    console.error("Failed to get target: " + error);
  }
};

export const IsTargetsCollectionEmpty = async () => {
  try {
    const userUid = auth.currentUser.uid;

    return await firestore
      .collection("targets")
      .doc(userUid)
      .collection("targets")
      .limit(1)
      .get()
      .then(async (query) => {
        return query.empty;
      });
  } catch (error) {
    captureException("Failed to check if targets's collection is empty", error);
    console.error("Failed to check if targets's collection is empty: " + error);
  }
};
